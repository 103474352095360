import React from "react";
import Button from "../primitives/Button";

const Container = ({ children }) => {
  return <div className="flex h-screen overflow-y-hidden">{children}</div>;
};

const SideBar = ({ children }) => {
  return (
    <div
      className="
absolute pt-20 pb-4
  h-screen
  w-80 font-bold max-h-screen  border-r border-gray-200 px-4 overflow-y-scroll shadow-xl"
    >
      {children}
    </div>
  );
};

const Content = ({ children, button }) => {
  return (
    <div className="max-h-screen">
      <div className="bg-white px-4 items-center border-b border-gray-200 z-10  w-screen h-16 relative flex justify-between">
        {button && (
          <Button onClick={button?.onClick} disabled={button?.disabled}>
            {button?.name}
          </Button>
        )}
      </div>
      <div
        className="left-80 absolute   overflow-auto"
        style={{
          height: "calc(100vh - 65px)",
          width: "calc(100% - 322px)",
          zIndex: "",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default { Container, SideBar, Content };

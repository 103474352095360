import { Dialog, Tab, Transition } from "@headlessui/react";
import { Fragment } from "react";
import FormEditPage from "./FormEditPage";
import Button from "../primitives/Button";
import { requestCore } from "../../utils/request";
import { toast } from "react-toastify";

const ModalPageSettings = ({
  isOpen,
  setIsOpen,
  setPages,
  page,
  type = "INTERNAL",
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDelete = async () => {
    const req = await requestCore
      .delete(`/cms/page/${page?.id}`)
      .catch((err) => null);
    if (req) {
      toast.success("Página eliminada con éxito");
      setPages((old) => {
        const filtered = old.filter((item) => item.id !== page.id);

        return [...filtered];
      });
    } else {
      toast.warning("Error al eliminar porfavor inténtalo mas tarde");
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-5 flex justify-between"
                >
                  Editar página
                  <Button variant="danger" onClick={handleDelete}>
                    Eliminar
                  </Button>
                </Dialog.Title>
                {type === "INTERNAL" ? (
                  <Tabs page={page} setPages={setPages} />
                ) : (
                  <FormEditPage page={page} setPages={setPages} type={type} />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Tabs = ({ page, setPages }) => (
  <Tab.Group>
    <Tab.List className="flex space-x-1 rounded-xl bg-primary-100 p-1 justify-center">
      <Tab
        as={Fragment}
        className={({ selected }) =>
          `${
            selected
              ? "bg-primary-500 text-primary-700"
              : "bg-white text-gray-800"
          } hover:bg-primary-100 w-full rounded-lg py-2.5 text-sm font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`
        }
      >
        <button>Editar página</button>
      </Tab>
      <Tab
        as={Fragment}
        className={({ selected }) =>
          `${
            selected ? "bg-primary-500 text-white" : "bg-white text-gray-800"
          } hover:bg-primary-100 w-full rounded-lg py-2.5 text-sm font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`
        }
      >
        <button>SEO</button>
      </Tab>
    </Tab.List>
    <Tab.Panels>
      <Tab.Panel>
        <FormEditPage page={page} setPages={setPages} />
      </Tab.Panel>
      <Tab.Panel>
        <div className="mt-8 mb-5">
          <h5 className="text-center">Próximamente</h5>
        </div>
      </Tab.Panel>
    </Tab.Panels>
  </Tab.Group>
);

export default ModalPageSettings;

import { createBrowserRouter } from "react-router-dom";
import Component from "./pages/components";
import Home from "./pages/home";
import Pages from "./pages/pages";
import Page from "./pages/pages/Page";
import Footer from "./pages/footer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/component/:id",
    element: <Component />,
  },
  {
    path: "/pages",
    element: <Pages />,
    errorElement: <h1>ERROR INTERNO</h1>,
  },
  {
    path: "/page/:id",
    element: <Page />,
  },

  {
    path: "/footer",
    element: <Footer />,
  },
]);

export default router;

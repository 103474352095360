import React from "react";
import { requestCore } from "../utils/request";
import { setWebsite } from "../reducers/websiteSlide";
import { toast } from "react-toastify";
import setRootColors from "../utils/setRootColors";
import { useDispatch } from "react-redux";

const useWebsite = () => {
  const dispatch = useDispatch();

  const updateWebsite = async (props) => {
    const body = {
      ...props,
      colors: props.colors ? JSON.stringify(props?.colors) : undefined,
    };

    const req = await requestCore
      .post("/cms/website", body)
      .catch((err) => null);

    if (req.data) {
      dispatch(setWebsite({ state: req.data }));
      if (props?.colors) {
        setRootColors(props.colors);
      }

      toast.success("Website guardado exitosamente");
    } else {
      toast.warning("Error creando el website");
    }
  };

  return { updateWebsite };
};

export default useWebsite;

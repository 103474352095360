import React, { useEffect, useRef, useState } from "react";
import { SlSettings } from "react-icons/sl";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const TextArea = ({
  id,
  onChange,
  value,
  name,
  settings = true,
  placeholder,
}) => {
  const [openSettings, setOpenSettings] = useState(false);
  const [data, setData] = useState(value);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setOpenSettings(false);
  });

  useEffect(() => {
    if (onChange) onChange(data, id);
  }, [JSON.stringify(data)]);

  return (
    <div className="col-span-full mt-5">
      <div className="flex justify-between relative">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {name}
        </label>

        {settings && (
          <>
            <SlSettings
              onClick={() => {
                setOpenSettings((old) => true);
              }}
              className="text-2xl cursor-pointer hover:text-gray-700"
            />
            {openSettings && (
              <div
                ref={ref}
                className="bg-white rounded-md py-2 px-2 absolute right-1 top-7  shadow-xl border border-gray-200 flex gap-2"
              >
                <Select
                  name="Tamaño"
                  id="fontSize"
                  value={value?.fontSize}
                  onChange={(e) => {
                    setData((old) => ({ ...old, fontSize: e.target.value }));
                  }}
                  options={[
                    { value: "h1", name: "Título 1" },
                    { value: "h2", name: "Título 2" },
                    { value: "h3", name: "Título 3" },
                    { value: "h4", name: "Título 4" },
                    { value: "h5", name: "Título 5" },
                    { value: "h6", name: "Título 6" },
                    { value: "p", name: "Párrrafo" },
                  ]}
                ></Select>
                <Select
                  id="color"
                  name="Color"
                  value={value?.color}
                  onChange={(e) => {
                    setData((old) => ({ ...old, color: e.target.value }));
                  }}
                  options={[
                    { value: "black", name: "Negro" },
                    { value: "white", name: "white" },
                    { value: "var(--primary100)", name: "Primario/100" },
                    { value: "var(--primary200)", name: "Primario/200" },
                    { value: "var(--primary300)", name: "Primario/300" },
                    { value: "var(--primary400)", name: "Primario/400" },
                    { value: "var(--primary500)", name: "Primario/500" },
                    { value: "var(--primary600)", name: "Primario/600" },
                    { value: "var(--primary700)", name: "Primario/700" },
                    { value: "var(--primary800)", name: "Primario/800" },
                    { value: "var(--primary900)", name: "Primario/900" },
                  ]}
                ></Select>
                <Select
                  name="Peso"
                  id="weight"
                  value={value?.weight}
                  onChange={(e) => {
                    setData((old) => ({ ...old, weight: e.target.value }));
                  }}
                  options={[
                    { value: "font-light", name: "Ligth" },
                    { value: "font-normal", name: "Normal" },
                    { value: "font-medium", name: "Medium" },
                    { value: "font-semibold", name: "Semibold" },
                    { value: "font-bold", name: "Bold" },
                    { value: "font-extrabold", name: "Extrabold" },
                  ]}
                ></Select>
              </div>
            )}
          </>
        )}
      </div>

      <div className="mt-2">
        <textarea
          id={id}
          name="about"
          rows="3"
          placeholder={placeholder}
          defaultValue={value?.value}
          onChange={(e) => {
            setData((old) => ({ ...old, value: e.target.value }));
          }}
          className="block px-1 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm "
        ></textarea>
      </div>
    </div>
  );
};

const Select = ({ options, id, name, onChange, value }) => {
  return (
    <div class="sm:col-span-3">
      <div class="">
        <select
          id={id}
          name={id}
          value={value}
          defaultValue={value}
          onChange={onChange}
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:max-w-xs sm:text-sm sm:leading-6"
        >
          <option value="" selected disabled hidden>
            {name}
          </option>
          {options?.map((option) => (
            <option value={option.value} key={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TextArea;

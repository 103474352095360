import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import Button from "../../primitives/Button";
import * as SlIcons from "react-icons/sl";
import * as FAIcons from "react-icons/fa6";

const Icons = ({ name, value, id, onChange }) => {
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="sm:col-span-3 mt-5">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {name}
      </label>
      <div className="mt-2 flex gap-5">
        <div className="w-10 h-10 bg-white border rounded-full flex items-center justify-center">
          <Icon Icons={FAIcons} iconName={value} className="text-2xl" />
        </div>

        <Button className="w-full" onClick={() => setIsOpen(true)}>
          Seleccionar
        </Button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 fixed"
                  >
                    Selecciona un icono
                  </Dialog.Title>
                  <div
                    className="mt-12 overflow-auto"
                    style={{ height: "70vh" }}
                  >
                    <DynamicFaIcon
                      Icons={FAIcons}
                      onChange={(e) => {
                        closeModal();
                        onChange(e, id);
                      }}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

const DynamicFaIcon = ({ Icons, onChange }) => {
  const allIcons = Object.keys(Icons);

  return (
    <div className=" grid grid-cols-12 ">
      {allIcons.map((iconName) => (
        <Icon
          key={iconName}
          className="text-7xl hover:bg-primary-100 p-2 rounded-md cursor-pointer"
          Icons={Icons}
          iconName={iconName}
          onChange={onChange}
          showName
        />
      ))}
    </div>
  );
};

const Icon = ({ Icons, iconName, onChange, className, showName }) => {
  const IconComponent = Icons[iconName];

  if (!IconComponent) {
    // Return a default one
    return null;
  }

  return (
    <div className="rounded-md">
      <IconComponent
        onClick={() => {
          if (onChange) onChange(iconName);
        }}
        className={className}
      />
      {showName && <p>{iconName}</p>}
    </div>
  );
};

export default Icons;

import React from "react";

const SkeletonCards = () => {
  return (
    <div role="status" className="max-w-sm animate-pulse mt-5">
      <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4"></div>
      <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4"></div>
      <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4"></div>
      <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4"></div>
      <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4"></div>
      <div className="h-12 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4"></div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default SkeletonCards;

import { createSlice } from "@reduxjs/toolkit";

export const pagesSlide = createSlice({
  name: "pages",
  initialState: {},
  reducers: {
    setPagesReduce: (state, { payload }) => {
      state.data = [...payload?.state];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPagesReduce } = pagesSlide.actions;

export default pagesSlide.reducer;

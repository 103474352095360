import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Button from "../primitives/Button";
import { requestCore } from "../../utils/request";
import { toast } from "react-toastify";

const ModalComponentSettings = ({
  isOpen,
  setIsOpen,
  setComponents,
  component,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDelete = async () => {
    const req = await requestCore
      .delete(`/cms/component/${component?.id}`)
      .catch((err) => null);
    if (req) {
      toast.success("Componente eliminado con éxito");
      setComponents((old) => {
        const filtered = old.filter((item) => item.id !== component.id);

        return [...filtered];
      });
      closeModal();
    } else {
      toast.warning("Error al eliminar por favor inténtalo mas tarde");
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-5 flex justify-between"
                >
                  Editar Componente
                  <Button variant="danger" onClick={handleDelete}>
                    Eliminar
                  </Button>
                </Dialog.Title>
                <div />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalComponentSettings;

import React, { useEffect, useState } from "react";

import MainLayout from "../../components/layout/MainLayout";
import { requestCore } from "../../utils/request";

import ItemCard from "../../components/primitives/ItemCard";
import { useNavigate, useParams } from "react-router-dom";
import SkeletonCards from "../../components/primitives/SkeletonCards";
import DynamicRender from "../../components/DynamicRender";
import ModalNewComponent from "../../components/Pages/ModalNewComponent";
import ModalComponentSettings from "../../components/Pages/ModalComponentSettings";

const Page = () => {
  const navigate = useNavigate();
  const [components, setComponents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openConfig, setOpenConfig] = useState(false);

  const [modalNewComponent, setModalNewComponent] = useState(false);
  const { id } = useParams();
  const [currentComponent, setCurrentComponent] = useState({});

  const getComponents = async () => {
    const req = await requestCore.get(`/cms/pageComponents/${id}`).catch(() => {
      setLoading(false);
    });
    setComponents(req.data);
    setLoading(false);
  };

  const handleNewComponent = () => {};

  useEffect(() => {
    getComponents();
  }, []);

  const reorder = async (id, newIdx) => {
    const newComponents = [...components];
    const index = newComponents.findIndex((item) => item.id === id);
    const temp = newComponents.find((item) => item.id === id);
    if (index !== -1) {
      // Si se encuentra el elemento en el arreglo, lo eliminamos
      newComponents.splice(index, 1);
    }

    // Insertar el nuevo elemento en el arreglo en la posición especificada por el nuevo orden
    newComponents.splice(newIdx, 0, temp);

    // Actualizar los órdenes de los elementos restantes en el arreglo
    const newOrder = [];
    for (let index = 0; index < newComponents.length; index++) {
      newComponents.order = index;
      newOrder.push({ id: newComponents[index].id, order: index + 1 });
    }

    // todo send order requests

    await requestCore
      .post("/cms/orderComponents", { components: newOrder })
      .catch((err) => null);

    setComponents([...newComponents]);
  };

  return (
    <MainLayout.Container>
      <ModalNewComponent
        isOpen={modalNewComponent}
        setIsOpen={setModalNewComponent}
        pageComponents={components}
        onNewComponent={(data) => {
          setComponents(data);
        }}
      />
      <MainLayout.SideBar>
        <h4>Componentes</h4>
        {loading && <SkeletonCards />}
        {components.map((item, idx) => (
          <ItemCard
            key={item.id}
            onSettingsClick={() => {
              setCurrentComponent(item);
              setOpenConfig(true);
            }}
            onClick={() => {
              navigate(`/component/${item.id}`);
            }}
            setOrderUp={
              idx === 0
                ? undefined
                : () => {
                    reorder(item.id, idx - 1);
                  }
            }
            setOrderDown={
              idx === components.length - 1
                ? undefined
                : () => {
                    reorder(item.id, idx + 1);
                  }
            }
          >
            {item?.component?.name}
          </ItemCard>
        ))}

        <hr className="py-2 border-gray-200"></hr>
        <ItemCard
          onClick={() => {
            navigate(`/footer`);
          }}
        >
          Pie de página
        </ItemCard>
        <ModalComponentSettings
          isOpen={openConfig}
          setIsOpen={setOpenConfig}
          setComponents={setComponents}
          component={currentComponent}
        />
      </MainLayout.SideBar>

      <MainLayout.Content
        button={{
          name: "Nuevo Componente",
          onClick: () => {
            setModalNewComponent(true);
          },
        }}
      >
        <div className="h-full w-full bg-white overflow-auto">
          <DynamicRender components={components} />
        </div>
      </MainLayout.Content>
    </MainLayout.Container>
  );
};

export default Page;

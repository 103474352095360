import React from "react";
import { FaAlignCenter, FaAlignLeft, FaAlignRight } from "react-icons/fa";

const Allign = ({ name, value, onChange, id, options }) => {
  return (
    <div className="sm:col-span-3 mt-5">
      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {name}
      </label>
      <div className="mt-2 flex gap-2">
        <div
          onClick={() => {
            onChange("LEFT", id);
          }}
          className={`border border-gray-200 flex cursor-pointer py-2 px-2 rounded-md  hover:bg-primary-500 cursor-pointer shadow-sm ${
            value === "LEFT" && "bg-primary-700 text-white"
          }`}
        >
          <FaAlignLeft /> <small className="ml-2">Izquierda</small>
        </div>
        {options.includes("CENTER") && (
          <div
            onClick={() => {
              onChange("CENTER", id);
            }}
            className={`border border-gray-200 flex cursor-pointer py-2 px-2 rounded-md  hover:bg-primary-500 cursor-pointer shadow-sm ${
              value === "CENTER" && "bg-primary-700 text-white"
            }`}
          >
            <FaAlignCenter /> <small className="ml-2">Centro</small>
          </div>
        )}
        {options.includes("RIGHT") && (
          <div
            onClick={() => {
              onChange("RIGHT", id);
            }}
            className={`border border-gray-200 flex cursor-pointer py-2 px-2 rounded-md  hover:bg-primary-500 cursor-pointer shadow-sm ${
              value === "RIGHT" && "bg-primary-700 text-white"
            }`}
          >
            <FaAlignRight /> <small className="ml-2">Derecha</small>
          </div>
        )}
      </div>
    </div>
  );
};

export default Allign;

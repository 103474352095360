import React, { useState } from "react";
import { FaCirclePlus } from "react-icons/fa6";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import guidGenerator from "../../../utils/guidGenerator";
import DynamicForm from "..";

const List = ({ name, form, value, id, onChange }) => {
  const [data, setData] = useState(value);
  const [current, setCurrent] = useState("");

  const newItem = () => {
    const dataTemp = data || [];
    dataTemp.push({ id: guidGenerator() });
    setData([...dataTemp]);
  };

  const removeItem = (index) => {
    const dataTemp = data;
    const x = dataTemp.splice(index, 1);
  };

  const handleChange = (newData, index) => {
    const dataTemp = data;
    dataTemp[index] = { ...dataTemp[index], ...newData };
    setData([...dataTemp]);
    onChange(dataTemp, id, index);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {name}
        </label>
        <FaCirclePlus
          onClick={newItem}
          className="text-3xl text-primary-700 hover:text-primary-500 cursor-pointer"
        />
      </div>

      <div className="mt-5">
        {data?.map((item, idx) => (
          <Item
            key={item.id}
            idx={idx + 1}
            form={form}
            value={data?.[idx]}
            onChange={(e, id) => {
              handleChange({ [id]: e }, idx);
            }}
            active={item.id == current}
            onClick={() => {
              setCurrent((old) => (old === item.id ? "" : item.id));
            }}
          ></Item>
        ))}
      </div>
    </div>
  );
};

const Item = ({ active, onClick, idx, form, onChange, value }) => {
  return (
    <div className="mt-4">
      <div
        onClick={onClick}
        className={` ${
          active && "text-primary-700"
        } flex   items-center justify-between border-b border-gray-200 cursor-pointer hover:text-primary-500 pb-3`}
      >
        Elemento {idx}
        <IoIosArrowDown />
      </div>
      {active && (
        <div className="mt-3 border-b border-gray-200 pb-5">
          <DynamicForm
            value={value}
            onChange={onChange}
            inputs={form}
          ></DynamicForm>
        </div>
      )}
    </div>
  );
};
export default List;

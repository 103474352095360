import React, { useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { requestCore } from "../../utils/request";
import { RenderComponent } from "../DynamicRender";
import Button from "../primitives/Button";
import { useNavigate, useParams } from "react-router-dom";

const ModalNewComponent = ({
  setIsOpen,
  isOpen,
  pageComponents,
  onNewComponent,
}) => {
  const navigate = useNavigate();

  const [components, setComponents] = useState([]);
  const [currentComponent, setCurrentComponent] = useState(null);
  const { id } = useParams();
  const [sending, setSending] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const getComponents = async () => {
    const req = await requestCore.get("/cms/components");
    if (req) {
      setComponents(req.data);
      setCurrentComponent(req.data[0]);
    }
  };

  const handleNewComponent = async () => {
    setSending(true);

    const req = await requestCore
      .post(`/cms/pageComponents`, {
        component_id: currentComponent.id,
        page_id: id,
        columns: 12,
        order: pageComponents?.length + 1,
      })
      .catch((err) => {
        return null;
      });

    if (req) {
      onNewComponent([...pageComponents, req.data]);
      navigate(`/component/${req.data.id}`);
      closeModal();
    }
    setSending(false);
  };

  useEffect(() => {
    getComponents();
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full  overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                style={{ height: "95vh" }}
              >
                <div className="flex items-center gap-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Nuevo componente
                  </Dialog.Title>
                  {currentComponent && (
                    <Button
                      className="self-end pl-10"
                      onClick={handleNewComponent}
                      disabled={sending}
                    >
                      Agregar
                    </Button>
                  )}
                </div>

                <div className="flex gap-5 mt-5">
                  <div style={{ height: "80vh" }} className="overflow-y-auto">
                    {components.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => setCurrentComponent(item)}
                        className={`${
                          currentComponent.id === item.id && "bg-primary-300"
                        } px-4 py-2 my-2 cursor-pointer hover:bg-primary-100 rounded-lg w-52`}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>

                  <div
                    className="border border-gray-200 rounded-lg w-full  m-auto block relative overflow-y-auto"
                    style={{ height: "80vh" }}
                  >
                    <RenderComponent code={currentComponent?.code} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalNewComponent;

import React, { useState } from "react";
import { useEffect } from "react";

const BorderRadius = ({ colors, setColors }) => {
  const handleChange = (radius) => {
    setColors((old) => ({ ...old, "border-radius": radius }));
  };

  const [active, setActive] = useState(colors["border-radius"]);

  useEffect(() => {
    if (colors["border-radius"]) setActive(colors["border-radius"]);
  }, [colors]);

  const RADIUS = [
    {
      className: "sm",
      radius: "2px",
    },
    {
      className: "md",
      radius: "6px",
    },
    {
      className: "lg",
      radius: "8px",
    },
    {
      className: "xl",
      radius: "12px",
    },
    {
      className: "2xl",
      radius: "16px",
    },
  ];
  return (
    <div className="mt-5">
      <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
        Border radius
      </label>
      <div className="flex gap-5">
        {RADIUS.map((item) => (
          <Item
            key={item.className}
            radius={item.className}
            onClick={() => {
              handleChange(item.radius);
            }}
            active={item.radius === active}
          />
        ))}
      </div>
    </div>
  );
};

const Item = ({ radius, onClick, active }) => {
  return (
    <div
      onClick={onClick}
      className={`${
        active && "bg-primary-500"
      }  border hover:bg-primary-200 cursor-pointer hover:text-white border-gray-200 w-full h-16 rounded-lg flex justify-center items-center`}
    >
      <div
        className={`border-2 ${
          active ? "border-white" : "border-primary-600"
        }  h-10 w-10 rounded rounded-${radius} `}
      >
        {" "}
      </div>
    </div>
  );
};

export default BorderRadius;

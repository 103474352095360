import axios from "axios";
import getCookie from "./getCookie";

export const requestCore = axios.create({
  baseURL: process.env.REACT_APP_API_URL_INVENTORIES,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("dgsToken") || "{}")?.token
    }`,
  },
});

export const requestSSO = axios.create({
  baseURL: process.env.REACT_APP_API_URL_SSO,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("dgsToken") || "{}")?.token
    }`,
  },
});

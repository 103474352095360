import "./App.css";
import { RouterProvider, useNavigate } from "react-router-dom";
import router from "./routes";
import { useEffect, useState } from "react";
import getCookie from "./utils/getCookie";
import { requestCore } from "./utils/request";
import { useDispatch } from "react-redux";
import { setWebsite } from "./reducers/websiteSlide";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import setRootColors from "./utils/setRootColors";
import Login from "./pages/login";
import NewWebsite from "./pages/NewWebsite";
import { LuLoader2 } from "react-icons/lu";

function App() {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);
  const [login, setLogin] = useState(false);
  const [newWebsite, setNewWebsite] = useState(false);

  useEffect(() => {
    getWebsite();
  }, []);

  const getWebsite = async (token) => {
    const req = await requestCore
      .get(
        "/cms/website",
        token
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : undefined
      )
      .catch((err) => {
        if (err.response.status === 401) {
          setLogin(true);
        } else {
          setNewWebsite(true);
        }
        return null;
      });

    if (req?.data) {
      dispatch(setWebsite({ state: req.data }));
      const colors = JSON.parse(req.data.colors);
      setRootColors(colors);
    }

    setloading(false);
  };

  if (newWebsite) {
    return <NewWebsite />;
  }

  if (loading) {
    return (
      <div className=" justify-center grid text-center items-center mt-10">
        <LuLoader2 className="text-4xl animate-spin text-primary-600" />
      </div>
    );
  }

  if (login) {
    return <Login onLogin={getWebsite} />;
  }

  return (
    <div className="absolute top-0 h-full left-0 w-screen">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

import React, { useState } from "react";
import MainLayout from "../../components/layout/MainLayout";
import { TbWorldWww, TbAdjustmentsFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import ModalSettings from "../../components/home/ModalSettings";
import { useSelector } from "react-redux";
import { LuFolderTree } from "react-icons/lu";

const Home = () => {
  const navigate = useNavigate();
  const [modalSettings, setModalSettings] = useState(false);
  const { data: website } = useSelector((state) => state.website);

  return (
    <MainLayout.Container>
      <MainLayout.SideBar>
        <MenuItem
          Icon={LuFolderTree}
          name="Páginas"
          description="Edita el contenido de tu website"
          onClick={() => navigate("/pages")}
        />
        <MenuItem
          Icon={TbWorldWww}
          name="Preview en vivo"
          description="Navega por el sitio web "
          onClick={() => {
            window.open(
              `${`https://main.d1osb3nbbtfpfu.amplifyapp.com/?code=${website?.code}`}`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
        />
        <MenuItem
          Icon={TbAdjustmentsFilled}
          name="Configuración"
          description="Edita la configuración del website"
          onClick={() => {
            setModalSettings(true);
          }}
        />
      </MainLayout.SideBar>
      <ModalSettings isOpen={modalSettings} setIsOpen={setModalSettings} />

      <MainLayout.Content>
        <div className="h-full w-full bg-gray-700 relative"></div>
      </MainLayout.Content>
    </MainLayout.Container>
  );
};

const MenuItem = ({ Icon, name, description, onClick }) => (
  <div
    onClick={onClick}
    className="cursor-pointer flex justify-between hover:text-primary-600 mb-3 mt-2 border-b pb-2"
  >
    <div>
      <div className="text-xl font-medium ">{name}</div>
      <p className="font-medium text-sm">{description}</p>
    </div>
    <Icon className="text-2xl text-primary-700" />
  </div>
);
export default Home;

import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import generateColors from "../../utils/colorsGenerator";

const Colors = ({ colors, setColors }) => {
  const [color, setColor] = useState(colors?.primary600 || "#3f4d67");

  const validateColor = (value) => {
    if (/^#[0-9A-Fa-f]*$/.test(value)) {
      setColor(value);
    }
  };

  useEffect(() => {
    const newColors = generateColors(color);
    setColors(newColors);
  }, [color]);

  return (
    <div>
      <div className="m-auto block pointer-color-picker" />
      <div className="  mt-5  mb-2">
        <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
          Color
        </label>

        <div className="flex">
          <div className="d-flex">
            <HexColorPicker
              color={color}
              onChange={(e) => {
                setColor(e);
              }}
            />
          </div>
          <div className="grid grid-cols-3">
            {Object.keys(colors).map((item) => (
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "8px",
                  margin: "5px",
                  background: colors?.[item],
                }}
              />
            ))}
          </div>
        </div>

        <div className="border border-gray-400 rounded-lg py-2 mt-2">
          <div className="flex justify-between px-3">
            <input
              type="text"
              className="border-0 input-color focus:outline-none text-gray-900 text-sm"
              value={color}
              maxLength={7}
              onChange={(e) => {
                validateColor(e.target.value);
              }}
            />
            <span
              className="w-8 h-5 rounded-md"
              style={{ background: color }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Colors;

import React, { useState } from "react";
import { Check, Input } from "../dynamicForm/primitives";
import { toast } from "react-toastify";
import { requestCore } from "../../utils/request";
import Button from "../primitives/Button";

const FormEditPage = ({ page, setPages, type }) => {
  const [name, setName] = useState(page?.name);
  const [path, setPath] = useState(page?.path);
  const [showInHeader, setShowInHeader] = useState(page?.showInHeader);
  const [showInFooter, setShowInFooter] = useState(page?.showInFooter);

  const handleSave = async () => {
    if (!name || !path) {
      toast.warning("El tipo de página es obligatorio");
      return;
    }
    const req = await requestCore
      .patch(`/cms/page/${page?.id}`, {
        name,
        path,
        showInHeader,
        showInFooter,
      })
      .catch((err) => {
        toast.warning(
          err?.response?.data?.message ||
            "Error al guardar la página, inténtalo más tarde."
        );

        return null;
      });

    if (req) {
      toast.success("Página actualizada con éxito");
      setPages((old) => {
        const pages = old.map((item) =>
          item.id === req?.data?.id ? req.data : item
        );

        return [...pages];
      });
    }
  };

  return (
    <div className="mt-8">
      <Input
        name="Nombre"
        value={name}
        onChange={(e) => {
          if (type !== "EXTERNAL")
            setPath(`/${e?.replace(" ", "-")?.toLowerCase()}`);
          setName(e);
        }}
      />

      {type !== "GROUP" && (
        <Input
          name={"Path"}
          value={path}
          onChange={(e) => {
            setPath(e);
          }}
        />
      )}
      <div className="flex gap-2">
        <Check
          id="showInHeader"
          name="Mostrar en la barra de navegación"
          value={showInHeader}
          onChange={(e) => setShowInHeader(e)}
        />
        <Check
          id="showInFooter"
          name="Mostrar en footer"
          value={showInFooter}
          onChange={(e) => setShowInFooter(e)}
        />
      </div>
      <div className="flex justify-end">
        <Button className="mt-7" onClick={handleSave}>
          Guardar
        </Button>
      </div>
    </div>
  );
};

export default FormEditPage;

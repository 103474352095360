import React, { useEffect, useState } from "react";

import MainLayout from "../../components/layout/MainLayout";
import { requestCore } from "../../utils/request";

import SkeletonCards from "../../components/primitives/SkeletonCards";
import ModalNewPage from "../../components/Pages/ModalNewPage";
import sortPages from "../../utils/sortPages";
import { toast } from "react-toastify";
import TreePages from "../../components/Pages/TreePages";
import { NavbarV1 } from "dgs-cms-components";
import { useDispatch } from "react-redux";
import { setPagesReduce } from "../../reducers/pagesSlide";

const Pages = () => {
  const dispatch = useDispatch();
  const [pages, setPages] = useState([]);
  const [logo, setLogo] = useState("");
  const [loading, setLoading] = useState(true);
  const [openModalNewPage, setOpenModalNewPage] = useState(false);
  const [treePages, setTreePages] = useState([]);
  const [currentEditGroup, setCurrentEditGroup] = useState(null);

  const getPages = async () => {
    const req = await requestCore.get("/cms/pages").catch(() => {});
    const reqConfig = await requestCore.get("/cms/website").catch((err) => {});

    setLogo(reqConfig.data?.logo);
    setPages(req.data);
    setLoading(false);
  };

  useEffect(() => {
    getPages();
  }, []);

  useEffect(() => {
    if (pages) {
      const temp = sortPages(pages);
      setTreePages(temp);
    }
  }, [pages]);

  const handleNewPage = () => {
    setOpenModalNewPage(true);
  };

  const handleUpdateParent = async (id) => {
    const req = await requestCore
      .patch(`/cms/page/${id}`, {
        parent_id: currentEditGroup?.id || null,
      })
      .catch((err) => {
        toast.warning(
          err?.response?.data?.message || "Error actualizando la página"
        );
        return null;
      });

    if (req?.data) {
      setCurrentEditGroup(null);
      const temp = pages.map((item) => {
        if (item.id === req?.data?.id) {
          return req?.data;
        } else {
          return item;
        }
      });
      setPages(temp);
    }
  };

  return (
    <MainLayout.Container>
      <ModalNewPage
        isOpen={openModalNewPage}
        setIsOpen={setOpenModalNewPage}
        pages={pages}
        setPages={setPages}
      />
      <MainLayout.SideBar>
        <h4>Páginas</h4>
        {loading && <SkeletonCards />}

        {treePages.map((item) => (
          <TreePages
            key={item.id}
            item={item}
            currentEditGroup={currentEditGroup}
            setCurrentEditGroup={setCurrentEditGroup}
            handleUpdateParent={handleUpdateParent}
            setPages={setPages}
          />
        ))}
        <p
          className={`text-center  pt-3 pb-2 hover:text-primary-500 hover:underline`}
          onClick={() => setCurrentEditGroup({})}
        >
          Agrega páginas hijas
        </p>
      </MainLayout.SideBar>

      <MainLayout.Content
        button={{ name: "Nueva página", onClick: handleNewPage }}
      >
        <div className="h-full w-full bg-gray-700 relative">
          <NavbarV1
            pages={treePages}
            buttons={[]}
            logo={logo}
            className="relative"
          />
        </div>
      </MainLayout.Content>
    </MainLayout.Container>
  );
};

export default Pages;

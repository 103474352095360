import React, { useState } from "react";
import { FaCirclePlus } from "react-icons/fa6";
import guidGenerator from "../../../../utils/guidGenerator";
import Item from "./Item";

const Form = ({ onChange, id, value, name }) => {
  const [data, setData] = useState(value || []);
  const [itemActive, setItemActive] = useState(null);
  const newItem = () => {
    const dataTemp = data || [];
    dataTemp.push({ id: guidGenerator() });
    setData([...dataTemp]);
  };

  const handleChange = (newData, index) => {
    const dataTemp = data;
    dataTemp[index] = { ...dataTemp[index], ...newData };
    setData([...dataTemp]);
    onChange(dataTemp, id);
  };
  return (
    <div>
      <div className="mt-5">
        <div className="flex justify-between">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            {name}
          </label>
          <FaCirclePlus
            onClick={newItem}
            className="text-3xl text-primary-700 hover:text-primary-500 cursor-pointer"
          />
        </div>

        <div className="mt-5">
          {data?.map((item, idx) => (
            <Item
              key={item.id}
              idx={idx + 1}
              id={idx}
              active={itemActive}
              setActive={setItemActive}
              value={data?.[idx]}
              onChange={(e, id) => {
                handleChange(e, id);
              }}
            ></Item>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Form;

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { FaExternalLinkSquareAlt, FaFolder } from "react-icons/fa";
import { FaFolderTree } from "react-icons/fa6";
import Input from "../dynamicForm/primitives/Input";
import Button from "../primitives/Button";
import { requestCore } from "../../utils/request";
import { toast } from "react-toastify";
import { Check } from "../dynamicForm/primitives";
import { useEffect } from "react";

const ModalNewPage = ({ isOpen, setIsOpen, pages, setPages }) => {
  const [pageType, setPageType] = useState(undefined);
  const [name, setName] = useState("");
  const [path, setPath] = useState("");
  const [showInHeader, setShowInHeader] = useState("");
  const [showInFooter, setShowInFooter] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSave = async () => {
    if (!pageType || !name || !path) {
      toast.warning("El tipo de página es obligatorio");
      return;
    }
    const req = await requestCore
      .post(`/cms/page`, {
        name,
        path,
        showInHeader,
        showInFooter,
        order: pages?.length + 1, //todo page.lengths
        type: pageType,
      })
      .catch((err) => {
        toast.warning(
          err?.response?.data?.message ||
            "Error al guardar la página, inténtalo más tarde."
        );

        return null;
      });

    if (req) {
      toast.success("Página creada con éxito");
      setPages((old) => [...old, req.data]);
      closeModal();
    }
  };

  useEffect(() => {
    if (pageType === "GROUP") {
      setShowInHeader(true);
    }
  }, [pageType]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Nueva página
                </Dialog.Title>
                <div className="mt-8">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Tipo de página
                  </label>
                  <div className="flex gap-2 justify-center mt-2">
                    <ItemPage
                      icon={FaFolderTree}
                      name="Grupo"
                      active={pageType === "GROUP"}
                      onClick={() => {
                        setPageType("GROUP");
                      }}
                    />
                    <ItemPage
                      icon={FaFolder}
                      name="Interna"
                      active={pageType === "INTERNAL"}
                      onClick={() => {
                        setPageType("INTERNAL");
                      }}
                    />
                    <ItemPage
                      icon={FaExternalLinkSquareAlt}
                      onClick={() => {
                        setPageType("EXTERNAL");
                      }}
                      name="Externa"
                      active={pageType === "EXTERNAL"}
                    />
                  </div>

                  <Input
                    name="Nombre"
                    value={name}
                    onChange={(e) => {
                      if (pageType !== "EXTERNAL") {
                        setPath(`/${e?.replace(" ", "-")?.toLowerCase()}`);
                      }
                      setName(e);
                    }}
                  />

                  {pageType !== "GROUP" && (
                    <Input
                      name={pageType === "EXTERNAL" ? "URL externa" : "Path"}
                      value={path}
                      onChange={(e) => {
                        setPath(e);
                      }}
                    />
                  )}
                  <div className="flex gap-2">
                    <Check
                      id="showInHeader"
                      name="Mostrar en la barra de navegación"
                      value={showInHeader}
                      onChange={(e) => setShowInHeader(e)}
                    />
                    <Check
                      id="showInFooter"
                      name="Mostrar en footer"
                      value={showInFooter}
                      onChange={(e) => setShowInFooter(e)}
                    />
                  </div>
                  <div className="flex justify-end">
                    <Button className="mt-7" onClick={handleSave}>
                      Guardar
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const ItemPage = ({ icon: FaFolderTree, active, name, onClick }) => (
  <div
    onClick={onClick}
    className={`${
      active && "text-white bg-primary-600"
    } border border-gray-200 px-4 py-2 rounded-lg gap-2 flex hover:bg-primary-200 cursor-pointer`}
  >
    <FaFolderTree className="text-xl" /> {name}
  </div>
);

export default ModalNewPage;

import React, { useEffect, useState } from "react";

import MainLayout from "../../components/layout/MainLayout";
import { useParams } from "react-router-dom";
import { requestCore } from "../../utils/request";
import SkeletonCards from "../../components/primitives/SkeletonCards";
import DynamicForm from "../../components/dynamicForm";

import { RenderComponent } from "../../components/DynamicRender";
import { toast } from "react-toastify";

const Component = () => {
  const [data, setData] = useState({});
  const [withChange, setWithChange] = useState(false);
  const [component, setComponent] = useState(null);
  const [loading, setLoading] = useState(true);

  const [currentSlide, setCurrentSlide] = useState(undefined);
  const { id } = useParams();

  const getComponent = async () => {
    const req = await requestCore.get(`/cms/pageComponent/${id}`).catch(() => {
      setLoading(false);
    });

    if (req.data) {
      setComponent(req.data);

      setData(JSON.parse(req.data?.data || "{}"));
    }
    setLoading(false);
  };

  useEffect(() => {
    getComponent();

    window.onbeforeunload = (event) => {
      if (!withChange) {
        const e = event || window.event;

        e.preventDefault();
        if (e) {
          e.returnValue = ""; // Legacy method for cross browser support
        }
      }
      return void 0; // Legacy method for cross browser support
    };
  }, []);

  const handleChange = (value, id, idx) => {
    setCurrentSlide(idx);
    setData((old) => ({ ...old, [id]: value }));

    setWithChange(true);
  };

  const publish = async () => {
    const req = await requestCore
      .patch(`/cms/pageComponents/${component.id}`, {
        data: JSON.stringify(data),
      })
      .catch((err) => {
        toast.error("Algo salió mal, porfavor inténtalo más tarde");
      });

    if (req) {
      toast.success("Guardado con éxito");
      setWithChange(false);
    }
  };

  return (
    <MainLayout.Container>
      <MainLayout.SideBar>
        {loading && !component ? (
          <SkeletonCards />
        ) : (
          <>
            <h3>{component.component?.name}</h3>
            <DynamicForm
              value={data}
              onChange={handleChange}
              inputs={JSON.parse(component?.component?.form || "[]")}
            ></DynamicForm>
          </>
        )}
      </MainLayout.SideBar>

      <MainLayout.Content
        button={{
          name: "Guardar",
          disabled: !withChange,
          onClick: publish,
        }}
      >
        <RenderComponent
          code={component?.component?.code}
          data={data}
          onChange={handleChange}
          currentSlide={currentSlide}
        />
      </MainLayout.Content>
    </MainLayout.Container>
  );
};

export default Component;

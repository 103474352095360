import React from "react";

const Range = ({ id, data, value, onChange, name }) => {
  return (
    <div className="mt-5">
      <label
        for="default-range"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {name}
      </label>
      <input
        type="range"
        id={id}
        name={id}
        min="0.1"
        defaultValue={data}
        value={value}
        onChange={(e) => {
          onChange(e.target.value, id);
        }}
        max="1.9"
        step="0.1"
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
      />
    </div>
  );
};

export default Range;

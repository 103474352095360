import React, { useEffect, useState } from "react";
import Input from "../Input";

const Map = ({ onChange, id, value }) => {
  const [data, setData] = useState(value);

  useEffect(() => {
    if (onChange) onChange(data, id);
  }, [JSON.stringify(data)]);

  const handleChange = (val, id) => {
    setData((old) => ({ ...old, [id]: val }));
  };

  return (
    <div>
      <Input
        name="Latitud"
        id="latitude"
        value={value?.latitude}
        onChange={handleChange}
      />
      <Input
        name="Longitud"
        value={value?.longitude}
        id="longitude"
        onChange={handleChange}
      />
    </div>
  );
};

export default Map;

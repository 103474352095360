import { Tab } from "@headlessui/react";
import React, { Fragment } from "react";
import BasicSettings from "./BasicSettings";
import LogosSettings from "./LogosSettings";
import FooterSettings from "./FooterSettings";
import { useSelector } from "react-redux";

const Tabs = () => {
  const { data: website } = useSelector((state) => state.website);

  const OPTIONS = [
    { name: "Configuraciones básicas" },
    { name: "Logos " },
    { name: "Footer" },
  ];
  return (
    <Tab.Group>
      <Tab.List className="flex space-x-1 rounded-xl bg-primary-100 p-1 justify-center">
        {OPTIONS.map((item) => (
          <Tab
            key={item.name}
            as={Fragment}
            className={({ selected }) =>
              `${
                selected
                  ? "bg-primary-500-important text-white"
                  : "bg-white text-gray-800"
              } hover:bg-primary-100 w-full rounded-lg py-2.5 text-sm font-medium leading-5 ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`
            }
          >
            <button>{item.name}</button>
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <BasicSettings website={website} />
        </Tab.Panel>
        <Tab.Panel>
          <LogosSettings website={website} />
        </Tab.Panel>
        <Tab.Panel>
          <FooterSettings website={website} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default Tabs;

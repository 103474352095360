import React, { useCallback } from "react";
import * as primitives from "./primitives";

const DynamicForm = ({ inputs, value, onChange }) => {
  const handleChange = (newValue, id) => {
    onChange(newValue, id);
  };
  return (
    <div>
      <>
        {inputs?.map((item, index) => {
          const DynamicComponent = primitives[item.type];

          if (DynamicComponent) {
            return (
              <DynamicComponent
                key={index}
                {...item}
                value={value?.[item?.id]}
                onChange={(newValue) => {
                  handleChange(newValue, item.id);
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </>
    </div>
  );
};

export default DynamicForm;

import React, { useState } from "react";
import { Input } from "../dynamicForm/primitives";
import TextArea from "../primitives/TextArea";
import useWebsite from "../../hooks/useWebsite";
import Button from "../primitives/Button";

const FooterSettings = ({ website }) => {
  const [facebook, setFacebook] = useState(website?.facebook);
  const [instagram, setInstagram] = useState(website?.instagram);
  const [tikTok, setTikTok] = useState(website?.tik_tok);
  const [copyright, setCopyright] = useState(website?.copyright);
  const [linkedin, setLinkedin] = useState(website?.linkedin);
  const { updateWebsite } = useWebsite();

  const handleSave = async () => {
    updateWebsite({
      facebook,
      instagram,
      tik_tok: tikTok,
      copyright,
      linkedin,
    });
  };
  return (
    <div className="mt-5">
      <Input
        id="facebook"
        name="Facebook"
        value={facebook}
        onChange={(e) => {
          setFacebook(e);
        }}
      />
      <Input
        id="instagram"
        name="Instagram"
        value={instagram}
        onChange={(e) => {
          setInstagram(e);
        }}
      />
      <Input
        id="tikTok"
        name="Tik Tok"
        value={tikTok}
        onChange={(e) => {
          setTikTok(e);
        }}
      />
      <Input
        id="linkedin"
        name="Linkedin"
        value={linkedin}
        onChange={(e) => {
          setLinkedin(e);
        }}
      />
      <TextArea
        id="copyright"
        name="Copyright"
        value={copyright}
        onChange={(e) => {
          setCopyright(e);
        }}
      />

      <div className="flex justify-end mt-5">
        <Button onClick={handleSave}>Guardar</Button>
      </div>
    </div>
  );
};

export default FooterSettings;

import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const RichText = ({
  initialValue,
  id,
  value,
  onChange,
  height = 300,
  folderFiles = "media",
  name,
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.onbeforeunload = (event) => {
      return void 0; // Legacy method for cross browser support
    };
  }, []);

  const example_image_upload_handler = (
    blobInfo,
    success,
    failure,
    progress
  ) => {
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open(
      "POST",
      `${process.env.REACT_APP_API_URL_INVENTORIES}/upload_image_branding/${folderFiles}`
    );
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${localStorage.getItem("EnCasaProfessional")}`
    );

    xhr.upload.onprogress = function (e) {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = function () {
      var json;

      if (xhr.status === 403) {
        failure("HTTP Error: " + xhr.status, { remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure("HTTP Error: " + xhr.status);
        return;
      }

      json = JSON.parse(xhr.responseText);

      if (!json || typeof json.location != "string") {
        failure("Invalid JSON: " + xhr.responseText);
        return;
      }

      success(json.location);
    };

    xhr.onerror = function () {
      failure(
        "Image upload failed due to a XHR Transport error. Code: " + xhr.status
      );
    };

    formData = new FormData();

    formData.append("image", blobInfo.blob());

    xhr.send(formData);
  };

  return (
    <div className="mt-5">
      {loading && <p>Cargando...</p>}

      <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
        {name}
      </label>
      <Editor
        apiKey="bn55bm3teujt1njz4hdtjds0sun59j9y520qep0afzptu12i"
        initialValue={initialValue}
        value={value}
        onPostRender={() => setLoading(false)}
        language={"es"}
        init={{
          selector: "textarea", // change this value according to your HTML
          images_upload_handler: example_image_upload_handler,
          height,
          min_width: 300,
          width: 300,
          menubar: false,

          plugins: [
            "table advlist autolink lists link image charmap print preview anchor code",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime image table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect fontsizeselect lineheight | bold italic forecolor backcolor | \
             alignleft aligncenter alignright alignjustify | \
             table image | bullist numlist outdent indent | removeformat | code",
          lineheight_formats: "0.5 1 1.1 1.2 1.3 1.4 1.5 2",
        }}
        onEditorChange={(e) => {
          if (onChange) onChange(e, id);
        }}
      ></Editor>
    </div>
  );
};

export default RichText;

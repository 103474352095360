import React from "react";

const Input = ({ name, id, value, onChange, onBlur }) => {
  return (
    <div className="sm:col-span-3 mt-5">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {name}
      </label>
      <div className="mt-2">
        <input
          type="text"
          name={id}
          id={id}
          defaultValue={value}
          value={value}
          onBlur={(e) => {
            if (onBlur) onBlur(e.target.value, id);
          }}
          onChange={(e) => {
            if (onChange) onChange(e.target.value, id);
          }}
          className="block w-full px-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
};

export default Input;

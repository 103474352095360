/* eslint-disable no-restricted-syntax */
function unflatten(arr) {
  let arrElem;
  let mappedElem;
  const tree = [];
  const mappedArr = {};

  // First map the nodes of the array to an object -> create a hash table.
  for (let i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id].sublinks = [];
  }

  for (const id in mappedArr) {
    // eslint-disable-next-line no-prototype-builtins
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent_id array of children.
      if (mappedElem.parent_id) {
        mappedArr[mappedElem.parent_id]?.sublinks?.push(mappedElem);
      } else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
}

function orderPages(pages) {
  const newPages = pages.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });

  newPages.forEach((element) => {
    if (element.sublinks) {
      // eslint-disable-next-line no-param-reassign
      element.sublinks = orderPages(element.sublinks);
    }
  });

  return newPages;
}

// eslint-disable-next-line import/prefer-default-export
const sortPages = (pages) => {
  let newPages = unflatten(pages);
  newPages = orderPages(newPages);

  return newPages;
};

module.exports = sortPages;

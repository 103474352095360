import React, { useEffect } from "react";
import Input from "../Input";
import Check from "../Check";
import Select from "../Select";
import { useState } from "react";
import TextArea from "../TextArea";
import { GoColumns } from "react-icons/go";
import { FiSquare } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import RichText from "../RichText";

const Item = ({ onChange, value, id, active, setActive }) => {
  const [data, setData] = useState(value);
  const OPTIONS = [
    { id: 1, name: "Texto" },
    { id: 2, name: "Area de texto" },
    { id: 3, name: "Número" },
    { id: 4, name: "Celular" },
    { id: 5, name: "Correo" },
    { id: 6, name: "Opciones" },
    { id: 6, name: "Desplegable" },
    { id: 7, name: "Check" },
    { id: 8, name: "Párrafo" },
  ];

  const handleChange = (value, id) => {
    setData((old) => ({ ...old, [id]: value }));
  };

  useEffect(() => {
    onChange(data, id);
  }, [data]);

  if (active !== id) {
    return (
      <div
        onClick={() => {
          setActive(id);
        }}
        className={` ${
          active && "text-primary-700"
        } flex   items-center justify-between border-b border-gray-200 cursor-pointer hover:text-primary-500 pb-3 mt-2`}
      >
        {value?.name
          ? value?.name?.length > 25
            ? `${value?.name?.substring(0, 25)}...`
            : value?.name
          : "(SIN ASIGNAR NOMBRE)"}
        <IoIosArrowDown />
      </div>
    );
  }
  return (
    <div>
      <div className=" flex justify-end">
        <IoIosArrowUp
          onClick={() => {
            setActive(null);
          }}
          className="text-right mt-2 -mb-4 cursor-pointer hover:text-primary-500"
        />
      </div>

      {data.type === "Check" && (
        <RichText
          id="name"
          name="Texto"
          onChange={handleChange}
          value={data?.name}
          placeholder={`Ingrese texto..`}
        />
      )}

      {data.type !== "Check" && data.type !== "Párrafo" && (
        <>
          <Input
            id="name"
            name="Nombre"
            onChange={handleChange}
            value={value?.name}
          />
          <Input
            id="placeholder"
            name="Placeholder"
            onChange={handleChange}
            value={value.placeholder}
          />
        </>
      )}

      <Select
        name="Tipo"
        id="type"
        options={OPTIONS}
        onChange={handleChange}
        value={value.type}
      />

      {(data.type === "Opciones" || data.type === "Desplegable") && (
        <TextArea
          id="options"
          name="Opciones (Separadas por una línea)"
          settings={false}
          initialValue={value.options}
          onChange={handleChange}
          placeholder={`Item 1
        Item 2`}
        />
      )}
      {data.type === "Párrafo" ? (
        <RichText
          id="options"
          name="Texto"
          settings={false}
          value={data?.options}
          onChange={handleChange}
          placeholder={`Texto...`}
        />
      ) : (
        <Check
          value={value?.optional}
          name="Opcional"
          id="optional"
          onChange={handleChange}
        />
      )}

      <label className="block text-sm font-medium leading-6 text-gray-900 mt-5">
        Ancho
      </label>
      <div className="flex gap-5  mt-1">
        <div
          onClick={() => {
            handleChange("50", "size");
          }}
          className={`border border-gray-200 flex cursor-pointer py-2 px-2 rounded-md  hover:bg-primary-500 cursor-pointer shadow-sm ${
            value.size === "50" && "bg-primary-700 text-white"
          }`}
        >
          <GoColumns /> <small className="ml-2">50%</small>
        </div>
        <div
          onClick={() => {
            handleChange("100", "size");
          }}
          className={`border border-gray-200 flex cursor-pointer py-2 px-2 rounded-md  hover:bg-primary-500 cursor-pointer shadow-sm ${
            value.size === "100" && "bg-primary-700 text-white"
          }`}
        >
          <FiSquare /> <small className="ml-2">100 %</small>
        </div>
      </div>

      <hr className="mt-5" />
    </div>
  );
};

export default Item;

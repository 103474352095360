import React from "react";
import Input from "../Input";
import { useState } from "react";
import { useEffect } from "react";
import Check from "../Check";
import Button from "../Button";

const Products = ({ value, onChange, id }) => {
  const [data, setData] = useState(value);

  useEffect(() => {
    onChange(data, id);
  }, [JSON.stringify(data)]);

  const handleChange = (val, id) => {
    setData((old) => ({ ...old, [id]: val }));
  };
  return (
    <div className="mt-5">
      <Input
        id="category"
        name="Categoría (Disponible en inventarios)"
        value={value?.category}
        onChange={handleChange}
      ></Input>
      <Input
        id="api_key"
        name="Api key (Generado automáticamente)"
        value={value?.api_key}
        onChange={handleChange}
      ></Input>
      <hr className=" border-gray-300 my-5" />
      <p className="font-semibold ">Propiedades a mostrar</p>
      <Check
        id="name"
        name="Nombre"
        value={value?.name}
        onChange={handleChange}
      />
      <br />
      <Check
        id="description"
        name="Descripción"
        value={value?.description}
        onChange={handleChange}
      />
      <br />
      <Check
        id="price"
        name="Precio"
        value={value?.price}
        onChange={handleChange}
      />
      <br />
      <Check
        id="hasButton"
        name="Botón (redirección)"
        value={value?.hasButton}
        onChange={handleChange}
      />
      <br />
      {value?.hasButton && (
        <Button id="button" value={value?.button} onChange={handleChange} />
      )}
      <hr className=" border-gray-300 my-5" />
      <p className="font-semibold ">Estilo tarjeta</p>
      <Check
        id="shadow"
        name="Sombra"
        value={value?.shadow}
        onChange={handleChange}
      />

      <br />
    </div>
  );
};

export default Products;

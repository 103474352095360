import React from "react";
import { requestSSO } from "../../utils/request";
import { useState } from "react";
import { toast } from "react-toastify";
import { LuLoader2 } from "react-icons/lu";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const body = {
      email,
      password,
    };
    const req = await requestSSO
      .post("/professionals/login", body)
      .catch((err) => null);

    if (req?.data) {
      const userToken = { token: req.data?.token, information: req.data };
      localStorage.setItem("dgsToken", JSON.stringify(userToken));

      setLoading(false);
      window.location.reload(false);
      onLogin?.(req.data?.token);
    } else {
      setLoading(false);
      toast.warning("Usuario o contraseña incorrecta");
    }
  };
  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0 mt-20">
      <p className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        Websites Dogtorsoftware
      </p>
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Inicia sesión con tu cuenta principal
          </h1>
          <form className="space-y-4 md:space-y-6" action="#">
            <div>
              <label
                for="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Correo
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required=""
              />
            </div>
            <div>
              <label
                for="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Contraseña
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    required=""
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    for="remember"
                    className="text-gray-500 dark:text-gray-300"
                  >
                    Recuérdame
                  </label>
                </div>
              </div>
              {/* <a
                href="#"
                className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                Forgot password?
              </a> */}
            </div>
            <button
              disabled={loading}
              onClick={handleLogin}
              className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            >
              <span className="flex justify-center items-center gap-4">
                Iniciar sesión{" "}
                {loading && <LuLoader2 className="animate-spin" />}
              </span>
            </button>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              No tienes una cuenta todavía?{" "}
              <a
                href="#"
                className="font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                Crear cuenta
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

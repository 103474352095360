const setRootColors = (colors) => {
  for (const key in colors) {
    if (colors.hasOwnProperty(key)) {
      const value = colors[key];
      document.documentElement.style.setProperty(`--${key}`, value);
    }
  }
};

export default setRootColors;

import React from "react";
import GroupPage from "./GroupPage";
import InternalPage from "./InternalPage";
import { useNavigate } from "react-router-dom";

const TreePages = ({
  item,
  currentEditGroup,
  setCurrentEditGroup,
  handleUpdateParent,
  setPages,
}) => {
  const navigate = useNavigate();

  if (item.type === "GROUP") {
    return (
      <GroupPage
        id={item.id}
        onClick={() => {
          setCurrentEditGroup((old) => (old ? null : item));
        }}
        sublinks={item.sublinks}
        currentEditGroup={currentEditGroup}
        setCurrentEditGroup={setCurrentEditGroup}
        handleUpdateParent={handleUpdateParent}
        setPages={setPages}
        page={item}
      >
        {item.name}
      </GroupPage>
    );
  }

  return (
    <InternalPage
      key={item.id}
      page={item}
      setPages={setPages}
      onClick={() => {
        if (currentEditGroup) {
          handleUpdateParent(item.id);
        } else if (item.type.toUpperCase() === "INTERNAL") {
          navigate(`/page/${item.id}`);
        }
      }}
      className={`${currentEditGroup && "animate-pulse border-primary-600"}`}
    >
      {item.name}
    </InternalPage>
  );
};

export default TreePages;

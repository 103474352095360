import React from "react";

const TextArea = ({ id, onChange, value, name }) => {
  return (
    <div className="col-span-full mt-5">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {name}
      </label>

      <div className="mt-2">
        <textarea
          id={id}
          name="about"
          rows="3"
          defaultValue={value?.value}
          value={value?.value}
          onChange={(e) => {
            onChange(e.target.value, id);
          }}
          className="block px-1 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm "
        >
          {value}
        </textarea>
      </div>
    </div>
  );
};

export default TextArea;

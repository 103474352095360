import React, { useState } from "react";
import { Input } from "../dynamicForm/primitives";
import Colors from "../colors/Colors";
import Button from "../primitives/Button";
import useWebsite from "../../hooks/useWebsite";
import BorderRadius from "../BorderRadius";

const BasicSettings = ({ website }) => {
  const [name, setName] = useState(website?.name);
  const [colors, setColors] = useState(
    JSON.parse(website.colors || "{}") || {}
  );

  const { updateWebsite } = useWebsite();

  const handleSave = async () => {
    updateWebsite({ name, colors });
  };

  return (
    <div className="mt-5">
      <Input
        id="websiteName"
        value={name}
        onChange={(e) => {
          setName(e);
        }}
        name="Nombre del website"
      />
      <Colors colors={colors} setColors={setColors} />

      <BorderRadius colors={colors} setColors={setColors} />
      <div className="flex justify-end mt-5">
        <Button onClick={handleSave}>Guardar</Button>
      </div>
    </div>
  );
};

export default BasicSettings;

import React from "react";

const VARIANTS = {
  primary:
    "bg-primary-600 hover:bg-primary-500 focus-visible:outline-primary-600",
  danger: "bg-red-600 hover:bg-red-500 focus-visible:outline-red-600",
};

const Button = ({
  children,
  onClick,
  disabled,
  className,
  variant = "primary",
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${VARIANTS[variant]} rounded-md
  disabled:bg-gray-300
  disabled:cursor-not-allowed
   px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;

import React from "react";
import * as defaultComponents from "dgs-cms-components";
import { useNavigate } from "react-router-dom";

const DynamicRender = ({ components }) => {
  const navigate = useNavigate();
  return (
    <div>
      <>
        {components?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                navigate(`/component/${item.id}`);
              }}
            >
              <RenderComponent
                code={item?.component?.code}
                data={JSON.parse(item?.data || "{}")}
              />
            </div>
          );
        })}
      </>
    </div>
  );
};

export const RenderComponent = ({ code, data, currentSlide, onChange }) => {
  const DynamicComponent = defaultComponents[code];

  if (DynamicComponent) {
    return (
      <DynamicComponent
        data={data}
        currentSlide={currentSlide}
        editor
        onChange={onChange}
      />
    );
  } else {
    return null;
  }
};

export default DynamicRender;

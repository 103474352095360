import React, { useEffect, useState } from "react";
import Icons from "./Icons";

const Button = ({ id, value, onChange }) => {
  const [data, setData] = useState(value);

  useEffect(() => {
    if (onChange) onChange(data, id);
  }, [JSON.stringify(data)]);

  return (
    <div className="mt-5 mb-2">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Botón
      </label>
      <hr />

      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-6 text-gray-900 mt-2"
      >
        Nombre botón
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="name-btn"
          id="name-btn"
          value={value?.name}
          onChange={(e) => {
            setData((old) => ({ ...old, name: e.target.value }));
          }}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-6 text-gray-900 mt-2"
      >
        Url
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="btn-url"
          id="btn-url"
          value={value?.url}
          onChange={(e) => {
            setData((old) => ({ ...old, url: e.target.value }));
          }}
          autoComplete="given-name"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <label className="inline-flex items-center cursor-pointer mt-5">
        <input
          type="checkbox"
          id="external"
          className="sr-only peer"
          checked={value?.external}
          onChange={(e) => {
            setData((old) => ({ ...old, external: e.target.checked }));
          }}
        />
        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          Abrir URL en una nueva pestaña
        </span>
      </label>
      <Icons
        name="icon"
        label="Icon"
        value={value?.icon}
        onChange={(e) => {
          setData((old) => ({ ...old, icon: e }));
        }}
      />
      <hr className="mt-3" />
    </div>
  );
};

export default Button;

import React from "react";
import { SlSettings } from "react-icons/sl";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";

const ItemCard = ({
  children,
  onClick,
  setOrderUp,
  setOrderDown,
  className,
  onSettingsClick,
  interactionOff,
}) => {
  return (
    <div className="flex items-center">
      <div
        className={`px-4  w-full border bg-white border-gray-100 rounded-lg shadow-sm my-2  flex justify-between items-center ${className}`}
      >
        <p
          onClick={onClick}
          className={`font-medium block py-3 ${
            !interactionOff &&
            "hover:text-primary-600 hover:font-semibold cursor-pointer"
          } w-full`}
        >
          {children}
        </p>
        <SlSettings
          onClick={onSettingsClick}
          className="text-xl text-primary-800 hover:text-primary-600 cursor-pointer"
        />
      </div>

      {(setOrderUp || setOrderDown) && (
        <div className="ml-1">
          {setOrderUp && (
            <FaArrowAltCircleUp
              onClick={setOrderUp}
              className="text-primary-700 hover:text-primary-600 mb-1 text-lg cursor-pointer"
            />
          )}
          {setOrderDown && (
            <FaArrowAltCircleDown
              onClick={setOrderDown}
              className="text-primary-700 hover:text-primary-600 mt-1 text-lg cursor-pointer"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ItemCard;

const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = (r, g, b) =>
  `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

const generateColor = (color, percentage) => {
  const hex = color.replace("#", "");
  const p = (percentage > 100 ? 200 - percentage : percentage) / 100;

  const r = `0x${hex[0]}${hex[1]}`;
  const g = `0x${hex[2]}${hex[3]}`;
  const b = `0x${hex[4]}${hex[5]}`;
  const filter = percentage > 100 ? 0 : 256;
  let nR = Math.ceil(filter * (1 - p) + r * p);
  let nG = Math.ceil(filter * (1 - p) + g * p);
  let nB = Math.ceil(filter * (1 - p) + b * p);

  nR = nR <= 255 ? nR : 255;
  nG = nG <= 255 ? nG : 255;
  nB = nB <= 255 ? nB : 255;
  return rgbToHex(nR, nG, nB);
};

export const generateColors = (color) => {
  const c180 = generateColor(color, 180);
  const c150 = generateColor(color, 150);
  const c110 = generateColor(color, 110);
  const c80 = generateColor(color, 80);
  const c50 = generateColor(color, 50);
  const c30 = generateColor(color, 30);
  const c15 = generateColor(color, 15);
  const c10 = generateColor(color, 6);

  const data = {
    primary900: c180, //900
    primary800: c150, // 800
    primary700: c110, //700
    primary600: color, // 600
    primary500: c80, // 500
    primary400: c50, //400
    primary300: c30, //300
    primary200: c15, //200
    primary100: c10, // 100
  };

  return data;
};

export default generateColors;

import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/MainLayout";
import { Footer as FooterComponent } from "dgs-cms-components";
import { useSelector } from "react-redux";
import useWebsite from "../../hooks/useWebsite";
import { Input } from "../../components/dynamicForm/primitives";
import { requestCore } from "../../utils/request";

const Footer = () => {
  const { data: website } = useSelector((state) => state.website);

  const [facebook, setFacebook] = useState(website?.facebook);
  const [instagram, setInstagram] = useState(website?.instagram);
  const [tikTok, setTikTok] = useState(website?.tik_tok);
  const [copyright, setCopyright] = useState(
    JSON.parse(website.copyright) || [
      {
        type: "paragaph",
        children: [
          { text: "Horarios de Atención:", color: "#ffffff", bold: true },
        ],
      },
      { type: "paragaph", children: [{ color: "#ffffff", text: "" }] },
      {
        type: "paragaph",
        children: [
          { color: "#ffffff", text: "Lunes a Viernes", bold: true },
          { color: "#ffffff", text: ": 9:00 AM - 6:00 PM" },
        ],
      },
      {
        type: "paragaph",
        children: [
          { color: "#ffffff", text: "Sábado", bold: true },
          { color: "#ffffff", text: ": 10:00 AM - 2:00 PM" },
        ],
      },
      {
        type: "paragraph",
        children: [
          { color: "#ffffff", text: "Domingo y Festivos", bold: true },
          { color: "#ffffff", text: ": Cerrado" },
        ],
      },
      { type: "paragraph", children: [{ color: "#ffffff", text: "" }] },
      {
        type: "paragraph",
        children: [
          { color: "#ffffff", text: "Teléfono", bold: true },
          { color: "#ffffff", text: ": [Número de Teléfono]" },
        ],
      },
      {
        type: "paragraph",
        children: [
          { color: "#ffffff", text: "Email", bold: true },
          { color: "#ffffff", text: ": [Correo Electrónico]" },
        ],
      },
      {
        type: "paragraph",
        children: [
          { color: "#ffffff", text: "Dirección", bold: true },
          { color: "#ffffff", text: ": [Dirección Física]" },
        ],
      },
      { type: "paragaph", children: [{ color: "#ffffff", text: "" }] },
    ]
  );
  const [linkedin, setLinkedin] = useState(website?.linkedin);
  const [footerPages, setFooterPages] = useState([]);
  const { updateWebsite } = useWebsite();

  const getPages = async () => {
    const req = await requestCore.get("/cms/pages").catch(() => {});
    setFooterPages(req.data);
  };

  useEffect(() => {
    getPages();
  }, []);

  const handleSave = async () => {
    updateWebsite({
      facebook,
      instagram,
      tik_tok: tikTok,
      copyright: JSON.stringify(copyright),
      linkedin,
    });
  };
  return (
    <MainLayout.Container>
      <MainLayout.SideBar>
        <h4>Footer</h4>

        <Input
          id="facebook"
          name="Facebook"
          value={facebook}
          onChange={(e) => {
            setFacebook(e);
          }}
        />
        <Input
          id="instagram"
          name="Instagram"
          value={instagram}
          onChange={(e) => {
            setInstagram(e);
          }}
        />
        <Input
          id="tikTok"
          name="Tik Tok"
          value={tikTok}
          onChange={(e) => {
            setTikTok(e);
          }}
        />
        <Input
          id="linkedin"
          name="Linkedin"
          value={linkedin}
          onChange={(e) => {
            setLinkedin(e);
          }}
        />
      </MainLayout.SideBar>

      <MainLayout.Content
        button={{
          name: "Guardar",
          onClick: handleSave,
        }}
      >
        <div className="h-full w-full bg-white overflow-auto">
          <FooterComponent
            {...website}
            copyright={copyright}
            onChange={(e) => {
              setCopyright(e);
            }}
            links={footerPages}
          />
        </div>
      </MainLayout.Content>
    </MainLayout.Container>
  );
};

export default Footer;

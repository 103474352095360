import { createSlice } from "@reduxjs/toolkit";

export const websiteSlide = createSlice({
  name: "website",
  initialState: {},
  reducers: {
    setWebsite: (state, { payload }) => {
      state.data = { ...payload?.state };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWebsite } = websiteSlide.actions;

export default websiteSlide.reducer;

import React from "react";
import { requestCore } from "../../utils/request";

const index = () => {
  const handleNewWebsite = async () => {
    const req = await requestCore.post("/cms/website").catch(() => null);
  };
  return <div>New Website</div>;
};

export default index;

import React, { Fragment, useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import { Dialog, Transition } from "@headlessui/react";
import Input from "../Input";
import { TbPhoto } from "react-icons/tb";
import Button from "../../../primitives/Button";

const UploadImage = ({ isOpen, setIsOpen, setData, data }) => {
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    const body = new FormData();
    body.append("image", e.target.files[0]);

    setLoading(true);

    const parameters = {
      headers: new Headers({
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("dgsToken") || "{}")?.token
        }`,
      }),
    };
    parameters.headers["Content-Type"] = "application/json";

    fetch(
      `${process.env.REACT_APP_API_URL_INVENTORIES}/upload_image_branding/website`,
      {
        credentials: "same-origin",
        method: "POST",
        body,
        headers: parameters.headers,
      }
    )
      .then((response) => {
        response.json().then((res) => {
          setData((old) => ({ ...old, desktop: res.location }));
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-5 flex justify-between"
                >
                  Actualizar multimedia
                </Dialog.Title>
                <div className="mt-10">
                  <div className="text-center   border border-dashed border-gray-300 rounded-xl p-10">
                    <TbPhoto
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer  text-center rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>Subir imagen</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleImageChange}
                        />
                      </label>
                      {loading && <ImSpinner2 className="ml-3 animate-spin " />}
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF de menos de 10 mb
                    </p>
                  </div>
                  <div className="flex justify-center mt-4 items-center">
                    <div className="border border-gray-200 w-full h-0 mr-3" />O
                    <div className="border border-gray-200 w-full h-0 ml-3"></div>
                  </div>
                  <div>
                    <Input
                      name="URL de la imagen o URL de youtube"
                      onBlur={(e) => {
                        setData((old) => ({ ...old, desktop: e }));
                      }}
                    />
                  </div>
                  <div className="mt-10 flex justify-end">
                    <Button onClick={closeModal}>Cerrar</Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default UploadImage;

import React, { useState } from "react";
import { File } from "../dynamicForm/primitives";
import Button from "../primitives/Button";
import useWebsite from "../../hooks/useWebsite";

const LogosSettings = ({ website }) => {
  const [logo, setLogo] = useState(website?.logo);
  const [favicon, setFavicon] = useState(website?.favicon);

  const { updateWebsite } = useWebsite();

  const handleSave = async () => {
    updateWebsite({ logo, favicon });
  };

  return (
    <div className="mt-5">
      <File
        name="Logo"
        value={{ desktop: logo }}
        onChange={(e) => {
          setLogo(e?.desktop);
        }}
      />
      <File
        name="Favicon"
        value={{ desktop: favicon }}
        onChange={(e) => {
          setFavicon(e?.desktop);
        }}
      />
      <div className="flex justify-end mt-5">
        <Button onClick={handleSave}>Guardar</Button>
      </div>
    </div>
  );
};

export default LogosSettings;

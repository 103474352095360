import React, { useState } from "react";
import { SlSettings } from "react-icons/sl";
import { FaArrowAltCircleUp, FaArrowAltCircleDown } from "react-icons/fa";
import TreePages from "./TreePages";
import ModalPageSettings from "./ModalPageSettings";

const GroupPage = ({
  id,
  children,
  onClick,
  setOrder,
  className,
  sublinks,
  currentEditGroup,
  setCurrentEditGroup,
  handleUpdateParent,
  setPages,
  page,
}) => {
  const [modalSettings, setModalSettings] = useState(false);
  return (
    <div className="flex items-center">
      <div
        className={`px-4   w-full border bg-white border-gray-100 rounded-lg shadow-sm my-2  ${className}`}
      >
        <div className=" flex justify-between items-center">
          <p className=" font-medium block py-3 w-full ">{children}</p>
          <SlSettings
            onClick={() => {
              setModalSettings(true);
            }}
            className="text-xl text-primary-800 hover:text-primary-600 cursor-pointer"
          />
        </div>
        <div className="w-full min-h-12 border border-dashed border-gray-700 mb-3 rounded-lg cursor-pointer  px-1">
          {sublinks?.map((item) => (
            <TreePages
              key={item.id}
              item={item}
              currentEditGroup={currentEditGroup?.id !== id && currentEditGroup}
              setCurrentEditGroup={setCurrentEditGroup}
              handleUpdateParent={handleUpdateParent}
              setPages={setPages}
            />
          ))}
          <p
            className={`text-center  pt-3 pb-2 hover:text-primary-500 hover:underline`}
            onClick={onClick}
          >
            Agrega páginas hijas
          </p>
        </div>
      </div>

      {setOrder && (
        <div className="ml-1">
          <FaArrowAltCircleUp className="text-primary-700 hover:text-primary-600 mb-1 text-lg cursor-pointer" />
          <FaArrowAltCircleDown className="text-primary-700 hover:text-primary-600 mt-1 text-lg cursor-pointer" />
        </div>
      )}
      <ModalPageSettings
        isOpen={modalSettings}
        setIsOpen={setModalSettings}
        page={page}
        setPages={setPages}
        type="GROUP"
      />
    </div>
  );
};

export default GroupPage;

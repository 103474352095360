import React, { useState } from "react";
import ItemCard from "../primitives/ItemCard";
import ModalPageSettings from "./ModalPageSettings";

const InternalPage = ({ children, onClick, className, page, setPages }) => {
  const [modalSettings, setModalSettings] = useState(false);
  return (
    <>
      <ItemCard
        onClick={onClick}
        onSettingsClick={() => {
          setModalSettings(true);
        }}
        interactionOff={page.type === "EXTERNAL"}
        className={className}
      >
        {children}
      </ItemCard>
      <ModalPageSettings
        isOpen={modalSettings}
        setIsOpen={setModalSettings}
        page={page}
        setPages={setPages}
        type={page.type}
      />
    </>
  );
};

export default InternalPage;

import React, { Fragment, useEffect, useState } from "react";
import { SlSettings, SlTrash } from "react-icons/sl";

import Button from "../../../primitives/Button";
import { isYoutubeURL } from "../../../../utils/isYoutubeURL";
import UploadImage from "./UploadImage";
import ModalSettings from "./ModalSettings";

const File = ({ onChange, id, value, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(value);
  const [openSettings, setOpenSettings] = useState(false);
  useEffect(() => {
    if (onChange) onChange(data, id);
  }, [JSON.stringify(data)]);

  return (
    <div className="col-span-full mt-6">
      <UploadImage
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={data}
        setData={setData}
      />
      <ModalSettings setIsOpen={setOpenSettings} isOpen={openSettings} />
      <div className="flex justify-between">
        <label
          htmlFor="cover-photo"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {name}
        </label>

        <div className="flex gap-2">
          <SlTrash
            onClick={() => {
              setData((old) => ({ ...old, desktop: undefined }));
            }}
            className="text-xl text-red-800 hover:text-red-600 cursor-pointer"
          ></SlTrash>
          <SlSettings
            onClick={() => {
              setOpenSettings((old) => true);
            }}
            className="text-2xl cursor-pointer hover:text-gray-700"
          />
        </div>
      </div>

      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 ">
        {data?.desktop ? (
          isYoutubeURL(data?.desktop) ? (
            <p className="my-5">URL Youtube</p>
          ) : (
            <img
              className="max-w-60 h-auto mx-2"
              alt=""
              src={data?.desktop}
            ></img>
          )
        ) : (
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            className="my-5"
          >
            Seleccionar multimedia
          </Button>
        )}
      </div>
    </div>
  );
};

export default File;
